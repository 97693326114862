html, body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
}

body {
    font-size: 16px;
    font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    height: 100%;
}

@font-face {
    font-family: 'Arial Black Local';
    src: url('/fonts/Arial Black.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
