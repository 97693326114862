/**
 * CLUBBYBOY.com
 * by Peter Liu
 */

a,
.arrow {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:active, a:focus,
.arrow:active, .arrow:focus {
    outline: 0;
    outline: none;
    border: none;
    -moz-outline-style: none;
    background-color: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.view_wip {
    text-align: center;
    font-size: 20px;
}

.hidden {
    display: none!important;
}

.strike {
    text-decoration: line-through!important;
}

/* HOME NAVIGATION VIEW  */
#view_main {
    filter: blur(0);
}

#view_main.blur {
    transition: filter 0.6s;
    filter: blur(5px);
}

.main_landing {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
}

.main_landing a {
    color: black;
    text-decoration: none;
    text-transform: uppercase;
}

.main_landing a span {
    font-weight: bold;
}

.main_image {
    margin: 0 auto;
    max-width: 100%;
}

.main_image img {
    max-height: 70vh;
    max-width: 100%;
}

.feat_heading {
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
}

.main_dates {
    width: 90%;
    font-size: 18px;
    font-style: italic;
}

.main_dates div {
    margin-bottom: 8px;
}

.main_heading {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 4px;
}

.main_date {
    text-transform: uppercase;
}

.main_date span {
    font-weight: bold;
}

.main_booking {
    margin: 8px 0;
}

.main_booking a {
    font-size: 16px;
}

.main_blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    /* justify-content fix for safari/edge */
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
}

.main_blocks div {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 15px 0;
}

.main_blocks a {
    display: block;
}

.main_blocks img {
    width: 100%;
}

.main_navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0;
    margin: 0;
    border: 0;
    text-align: center;
    font-family: 'Arial Black', 'Arial Black Local';
}

.main_navigation svg {
  width: 100%;
  display: block;
  cursor: pointer;
  fill: #222;
}

.main_nav_container:hover > a > svg {
  fill: white;
}

.main_nav_container a:hover svg:hover {
  fill: #222;
}

.main_socials {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 auto;
}

.main_socials .row_icons {
    display: flex;
    margin: 0;
}

footer {
    text-align: center;
    font-size: 12px;
    padding: 20px 0;
}

/* OVERLAY VIEWS (function, clubcast, connection) */
#overlay_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.5;
    z-index: 1;
}

.view_overlay {
    position: fixed;
    background: white;
    z-index: 3;
    box-shadow: 0 0 8px 1px hsla(0,0%,95%,.5);

    font-weight: 300;
}

.row_icons {
    display: flex;

    /* justify-content fix for safari/edge */
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    margin: 1.5em 0 0;
    align-items: center;
}

.row_icons a {
    color: black;
}

/* FUNCTION VIEW */
#link_function {
    display: block;
    overflow: hidden;
    text-align: center;
}

#img_function {
    max-height: 70vh;
    user-select: none;
}

.img_badm {
    width: 52px;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

.arrow_left {
    position: absolute;
    top: 0;
    left: 0;
}

.arrow_right {
    position: absolute;
    top: 0;
    left: 100%;
}

/* FUNCTION VIEW */
#function_content .row_icons {
    margin: 0;
}

/* RECORD VIEW */
#record_content {
    overflow-y: scroll;
}

#record_img {
    width: 100%;
}

#record_links {
    margin: 1em 0;
}

#record_links ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#record_links ul li {
    margin: 4px 0;
}

#record_links ul li a {
    font-weight: bold;
    font-style: italic;
    color: black;
}

/* CLUBCAST VIEW */
#clubcast_body {
    overflow-y: scroll;
}

.clubcast_badge {
    display: block;
    margin: 0 auto;
}

/* BOUTIQUE VIEW */
#view_boutique {
    max-height: 90vh;
    overflow: auto;
}

#boutique_content {

}

#boutique_items {
    text-align: center;
}

.merch_item {
    display: inline-block;
    width: 45%;
    margin: 12px 2.5% 0;
}

.merch_item a {
    color: black;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    text-align: center;
}

.merch_item img {
    max-width: 100%;
}

.merch_item span {
    margin-top: 6px;
}

/* CONNECTION VIEW */
#connection_content {

}

#connection_body {
    text-align: left;
}

#connection_bio p:first-child {
    margin-top: 0;
}

#connection_bio p:last-child {
    margin-bottom: 0;
}

/* DESKTOP */
@media screen and (min-width: 769px) {

    ::-webkit-scrollbar {
        width: 0px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }

    .main_landing {
        min-height: 90vh;
        justify-content: space-evenly;
    }

    .main_feature {
/*        min-height: 100vh;*/
/*      margin-top: 10vh;*/
        width: 50%;
        justify-content: space-evenly;
    }

    .main_feature.feat1 {
        margin-top: 10vh;
    }

    .feat_heading {
        margin-bottom: 6vh;
        margin-top: 6vh;
    }

    .main_feature div {
        width: 100%;
    }

    .main_feature img {
        max-width: 66%;
    }

    .main_dates {
        margin-top: 3vh;
    }

    .main_blocks {
        margin-top: 6vh;
        min-height: 80vh;
        width: 50%;
    }

    .main_blocks div.half {
        width: 45%;
    }

    .main_blocks div.full {
        width: 100%;
    }

    .main_blocks div.half:last-child:nth-child(odd) {
        width: 45%;
        margin: 0 auto;
    }

    .main_blocks div.full:last-child:nth-child(odd) {
        width: 100%;
        margin: 0 auto;
    }

    /*.main_navigation {
        min-height: 70vh;
    }*/
    .main_navigation {
    	margin-top: 6vh;
    }

    .main_nav_container {
        width: 40vw;
    }

    .main_socials {
        height: 12vh;
        width: 45%;
    }

    .view_overlay {
        min-width: 400px;
        height: auto;
        padding: 24px 54px;
    }

    .arrow {
        width: 3em;
    }

    .arrow_right {
        transform: translate(-3em, 0);
    }

    #record_content {
        max-height: 70vh;
    }

    #record_link {
        display: block;
        width: 60%;
        margin: 0 auto;
    }

    #record_links {
        display: block;
        margin: 0 auto;
    }

    #clubcast_body {
        max-height: 70vh;
    }

    .clubcast_title {
        display: block;
        width: 75%;
        margin: 0 auto;
    }

    .clubcast_badge {
        width: 200px;
    }

    #connection_bio {
        width: 60%;
        height: 60vh;
        overflow-y: auto;
        margin-top: 1em;
        padding-right: 15px;
    }

    #connection_pic {
        position: fixed;
        top: 40px;
        width: 33%;
        right: 36px;
    }

    #connection_pic img {
        width: 100%;
    }

    #function_content svg,
    #clubcast_content svg,
    #connection_content svg {
        height: 28px;
    }

    .img_logo_icon {
        height: 20px;
    }

    .img_logo_ra {
        margin: 7px 8px 0;
    }

    .img_logo_tix {
        height: 28px;
    }

}

/* MOBILE & TABLET */
@media screen and (max-width: 768px) {

    .main_landing {
        justify-content: space-around;
    }

    .main_dates {
        font-size: 16px;
        margin-top: 2vh;
    }

    .main_feature {
        width: 80vw;
        justify-content: space-around;
    }

    .main_feature.feat1 {
        margin-top: 10vh;
    }

    .main_feature div {
        width: 100%;
    }

    .main_feature img {
        max-width: 100%;
    }

    .feat_heading {
    	margin-bottom: 3vh;
        margin-top: 3vh;
    }

    .main_booking a {
        font-size: 14px;
    }

    .main_blocks {
        min-height: 55vh;
        width: 80vw;
        margin: 30px auto;
    }

    .main_blocks div.half {
        width: 45%;
    }

    .main_blocks div.full {
        width: 100%;
    }

    .main_blocks div.half:last-child:nth-child(odd) {
        width: 45%;
        margin: 0 auto;
    }

    .main_blocks div.full:last-child:nth-child(odd) {
        width: 100%;
        margin: 0 auto;
    }

    .main_navigation {
        margin: 30px auto;
    }

    .main_socials {
        width: 75vw;
        margin: 30px auto;
    }

    .main_subscribe {
        width: 60vw;
        margin: 30px auto 0;
    }

    .main_subscribe input.email {
        text-align: center;
    }

    .view_overlay {
        min-width: 275px;
        max-width: 75vw;
        max-height: 75vh;
        padding: 16px 1.5em;
    }

    #img_function {
        max-width: 100%;
    }

    .arrow {
        width: 1.5em;
    }

    .arrow_right {
        transform: translate(-1.5em, 0);
    }

    .arrow svg {
        font-size: 1.5em;
    }

    #record_content {
        max-height: 55vh;
    }

    #record_links {
        display: block;
        margin: 0 auto;
        width: 100%;
    }

    #clubcast_body {
        max-height: 55vh;
    }

    .clubcast_title {
        display: block;
        width: 100%;
    }

    .clubcast_badge {
        width: 150px;
    }

    #connection_body {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        max-height: 45vh;
    }

    #connection_bio {
        width: 100%;
        height: auto;
        overflow-y: initial;
    }

    #connection_pic {
        width: 100%;
        text-align: center;
        position: initial;
    }

    #connection_pic img {
        width: 125px;
        margin-bottom: 1em;
    }

    #function_content svg,
    #clubcast_content svg,
    #connection_content svg {
        height: 23px;
        width: .75em!important;
    }

    #function_content .img_logo_ra {
        height: 17px;
        margin-top: 4px;
    }

    #function_content .img_logo_tix {
        height: 26px;
    }

    #connection_content .img_logo_ra {
        height: 14px;
        margin: 4px 4px 0;
    }

}

@media screen and (max-width: 768px) and (orientation: portrait) {
    .main_image {
        margin-top: 17vh;
    }

    .main_nav_container {
        max-width: 70vw;
    }

    .main_socials svg {
        font-size: 1.5em;
    }

    .main_socials .img_logo_ra {
        height: 16px;
    }
}

@media screen and (max-width: 768px) and (orientation: landscape) {
    .main_landing {
        min-height: 95vh;
    }

    .main_nav_container {
        max-width: 70vw;
    }

    .main_socials .img_logo_ra {
        height: 20px;
    }
}
